<template>
  <div>
    <!--begin::customer-->
    <div>
          <h3 class="card-label">
            {{ $t('material_list.material_list') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('material_list.item') }}<span class="text-danger">*</span></label>
                <!--              <div class="input-group mb-3">-->
                <div class="d-flex search-item-group input-group">
                  <select name="" id="item" v-model="f_by" class="custom-select">
                    <option value="name" selected>{{ $t('items.name') }}</option>
                    <option value="sku_code">{{ $t('items.sku_code') }}</option>
                  </select>
                  <multiselect v-model="item" style="width: 60%;"
                               id="item_id"
                               :placeholder="$t('bill_of_material.item')"
                               :label="f_by?f_by:'sku_code'"
                               :class="validation && validation.item_id ? 'is-invalid' : ''"
                               track-by="id"
                               :options="items_manufacture"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               :internal-search="false"
                               @search-change="getItemsManufacture(f_by, $event)">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="javascript:;"  @click="showItemModal(true)"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>

                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters')+' '+$t('bill_of_material.item') }}.</span>

                <span v-if="validation && validation.item_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.item_id[0] }}
                                    </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('material_list.item_details') }}</label>
                <div class="d-flex">
                  <img v-if="item && item.image_url" :src="item.image_url" style="width: 70px; height: 70px;" alt="">
                  <div class="pl-2 pr-2 text-dark">
                    <p class="mb-1" v-if="item && item.sku_code">{{ $t('items.sku_code') }}: {{ item.sku_code }}</p>
                    <p class="mb-1" v-if="item && item.name">{{ $t('items.name') }}: {{ item.name }}</p>
                    <p class="mb-1" v-if="item && item.sale_price">{{ $t('items.sale_price') }}: {{ item.sale_price }}</p>
                    <p class="mb-1" v-if="item && item.purchase_price">{{ $t('items.purchase_price') }}:
                      {{ item.purchase_price }}</p>
                    <p class="mb-1" v-if="item && item.sum_available_qty">{{ $t('items.sum_available_qty') }}:
                      {{ item.sum_available_qty }}</p>
                  </div>

                </div>

              </div>

            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>
                  {{ $t('material_list.is_active') }}
                </b-form-checkbox>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('material_list.description') }}</label>
                <textarea v-model="data.description" class="form-control"
                          :class="validation && validation.description ? 'is-invalid' : ''"></textarea>
                <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.description[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row mt-5">
        <div class="col-lg-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('material_list.sub_items') }}</h6>
            <button type="button" class="btn btn-primary" @click="addRepeater"><i
                class="fas fa-plus"></i>{{ $t('add_more') }}
            </button>
          </div>
          <div class="table-responsive bg-white">
            <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addRepeater" @keyup.alt.46="removeRepeater">
              <thead>
              <tr>
                <th width="250px">{{ $t('material_list.item_details') }}</th>
                <th width="400px">
                  <div class="d-flex justify-content-between">
                    <span>{{ $t('material_list.item') }}</span>
                    <a href="javascript:;" class="btn btn-primary btn-sm p-1" @click="showItemModal(false)">
                      <i class="fa fa-plus add-new"></i>
                    </a>
                  </div>
                </th>
                <th width="150px">{{ $t('material_list.quantity') }}</th>
                <th width="200px">
                  <div class="d-flex justify-content-between">
                    <span>{{ $t('material_list.unit') }}</span>
                    <a href="/settings/units" target="_blank" class="btn btn-primary btn-sm p-1">
                      <i class="fa fa-plus add-new"></i>
                    </a>
                  </div>
                </th>
                <th>{{ $t('material_list.is_active') }}</th>
                <th>{{ $t('actions') }}</th>
              </tr>

              </thead>
              <tbody>
              <tr v-for="(row, index) in sub_items" :key="index">
                <td>
                  <a :id="'popover-target-'+index" href="javascript:;" v-if="row.item"
                     class="d-flex cursor-pointer">
                    <img :src="row.item.image_url" style="width: 70px; height: 70px;" alt="">
                    <div class="pl-2 pr-2 text-dark">
                      <p class="mb-1" v-if="row.item.sku_code">{{ $t('items.sku_code') }}:
                        {{ row.item.sku_code }}</p>
                      <p class="mb-1" v-if="row.item.name">{{ $t('items.name') }}: {{ row.item.name }}</p>
                      <p class="mb-1" v-if="row.item.sale_price">{{ $t('items.sale_price') }}:
                        {{ row.item.sale_price }}</p>
                      <p class="mb-1" v-if="row.item.purchase_price">{{ $t('items.purchase_price') }}:
                        {{ row.item.purchase_price }}</p>
                      <p class="mb-1" v-if="row.item.sum_available_qty">{{ $t('items.sum_available_qty') }}:
                        {{ row.item.sum_available_qty }}</p>
                    </div>
                  </a>
                </td>
                <td>
                  <div class="d-flex search-item-group" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('material_list.item')">
                    <select name="" id="" v-model="row.f_by" class="custom-select" style="width: 100px;">
                      <option value="name" selected>{{ $t('items.name') }}</option>
                      <option value="sku_code">{{ $t('items.sku_code') }}</option>
                    </select>
                    <multiselect v-model="row.item"
                                 :placeholder="$t('material_list.item')"
                                 :label="row.f_by? row.f_by:'name'"
                                 track-by="id"
                                 :options="items"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 :internal-search="false"
                                 @search-change="getItems(row.f_by, $event)">
                    </multiselect>
                  </div>
                </td>

                <td><input v-model="row.quantity" type="number" class="form-control"></td>
                <td>
                  <multiselect v-model="row.unit" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('material_list.unit')"
                               :placeholder="$t('material_list.unit')"
                               label="name"
                               track-by="id"
                               :options="units"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                </td>

                <td>
                  <b-form-checkbox size="lg" v-model="row.is_active" name="check-button" switch></b-form-checkbox>
                </td>

                <td>
                  <v-icon style="color: #dc3545;" small v-if="sub_items.length > 1" @click="removeRepeater(index)">
                    mdi-delete
                  </v-icon>
                  <button class="btn btn-primary btn-sm m-2" @click="showModal(index)">
                    {{ $t('material_list.alternatives') }}
                  </button>

                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>



        <div class="pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6 mt-5">
              <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
              <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
            </div>
          </div>
        </div>

    </div>
    <!--end::customer-->
    <b-modal ref="modal" size="xl" hide-footer :title="$t('material_list.alternatives')">
      <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
        <button type="button" class="btn btn-primary" @click="addRepeaterAlternative"><i
            class="fas fa-plus"></i>{{ $t('add_more') }}
        </button>
      </div>
      <div class="table-responsive">
        <table class="table table-row-bordered w-max-content" @keyup.alt.enter="addRepeaterAlternative"
               @keyup.alt.46="removeRepeaterAlternative">
          <thead>
          <tr>
            <th width="250px">{{ $t('material_list.item_details') }}</th>
            <th width="300px">
              <div class="d-flex justify-content-between">
                <span>{{ $t('material_list.item') }}</span>
                <a href="javascript:;" class="btn btn-primary btn-sm p-1" @click="showItemModal(false, true)">
                  <i class="fa fa-plus add-new"></i>
                </a>
              </div>
            </th>
            <th width="150px">{{ $t('material_list.quantity') }}</th>
            <th width="200px">
              <div class="d-flex justify-content-between">
                <span>{{ $t('material_list.unit') }}</span>
                <a href="/settings/units" target="_blank" class="btn btn-primary btn-sm p-1">
                  <i class="fa fa-plus add-new"></i>
                </a>
              </div>
            </th>
            <th>{{ $t('material_list.is_active') }}</th>
            <th>{{ $t('actions') }}</th>
          </tr>

          </thead>
          <tbody>
          <tr v-for="(row, index) in sub_item_alternatives" :key="index">
            <td>
              <a :id="'popover-target-'+index" href="javascript:;" v-if="row.item" class="d-flex cursor-pointer">
                <img :src="row.item.image_url" style="width: 70px; height: 70px;" alt="">
                <div class="pl-2 pr-2 text-dark">
                  <p class="mb-1" v-if="row.item.sku_code">{{ $t('items.sku_code') }}: {{ row.item.sku_code }}</p>
                  <p class="mb-1" v-if="row.item.name">{{ $t('items.name') }}: {{ row.item.name }}</p>
                  <p class="mb-1" v-if="row.item.sale_price">{{ $t('items.sale_price') }}: {{ row.item.sale_price }}</p>
                  <p class="mb-1" v-if="row.item.purchase_price">{{ $t('items.purchase_price') }}:
                    {{ row.item.purchase_price }}</p>
                  <p class="mb-1" v-if="row.item.sum_available_qty">{{ $t('items.sum_available_qty') }}:
                    {{ row.item.sum_available_qty }}</p>
                </div>
              </a>
            </td>
            <td>
              <div class="d-flex search-item-group">
                <select name="" v-model="row.f_by" class="custom-select" style="width: 100px;">
                  <option value="name" selected>{{ $t('items.name') }}</option>
                  <option value="sku_code">{{ $t('items.sku_code') }}</option>
                </select>
                <multiselect v-model="row.item"
                             :placeholder="$t('material_list.item')"
                             :label="row.f_by? row.f_by:'name'"
                             track-by="id"
                             @input="checkItemNotMain(index, $event)"
                             :options="items"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :internal-search="false"
                             @search-change="getItems(row.f_by, $event)">
                </multiselect>
              </div>
              <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters')+' '+$t('material_list.item') }}.</span>
            </td>

            <td><input v-model="row.quantity" type="number" class="form-control"></td>
            <td>
              <multiselect v-model="row.unit"
                           :placeholder="$t('material_list.unit')"
                           label="name"
                           track-by="id"
                           :options="units"
                           :multiple="false"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false">
              </multiselect>
              <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters')+' '+$t('material_list.unit') }}.</span>
            </td>

            <td>
              <b-form-checkbox size="lg" v-model="row.is_active" name="check-button" switch></b-form-checkbox>
            </td>

            <td>
              <v-icon style="color: #dc3545;" small v-if="sub_item_alternatives.length > 1"
                      @click="removeRepeaterAlternative(index)">mdi-delete
              </v-icon>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </b-modal>

    <b-modal ref="modalItem" size="lg" hide-footer :title="$t('items.add_new_item')">
      <item-form @hide-modal="hideItemModal()" @handling-data="getDataAfterCreateNewItem" :is-manufacture="true"></item-form>
    </b-modal>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";
import ItemForm from "@/view/content/forms/ItemForm";

export default {
  name: "form-material-list",
  components: {'item-form': ItemForm},
  data() {
    return {
      mainRoute: 'manufacturers/materials',
      mainRouteDependency: 'base/dependency',

      data: {
        item_id: null,
        description: null,
        is_active: true,
      },
      // item_id
      // quantity
      // unit_id
      // is_active
      sub_items: [],
      sub_item_repeater: {
        id: null,
        item_id: null,
        item: null,
        unit: null,
        unit_id: null,
        quantity: null,
        is_active: true,
        alternatives: []
      },

      // sub_item_id
      // item_id
      // quantity
      // unit_id
      // is_active
      sub_item_alternatives: [],
      sub_item_alternatives_repeater: {
        id: null,
        item_id: null,
        item: null,
        unit: null,
        unit_id: null,
        quantity: null,
        is_active: true
      },

      item: null,
      items: [],
      items_manufacture: [],
      f_by: 'name',

      units: [],

      idEditing: this.$route.params.id,
      isEditing: false,

      validation: null,
      unit_default: null,
      index_sub_item_row: null,
      is_main: true,
      is_alternatives: false,

    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {

    item: function (val) {
      if (val)
        this.data.item_id = val.id;
      else
        this.data.item_id = null;
    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {

      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
        sub_items: this.sub_items,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'material-list.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {

      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
        sub_items: this.sub_items,
      }).then((response) => {
        this.validation = null;
        this.$router.push({name: 'material-list.index'});
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {

      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.item_id = response.data.data.item_id;
        this.data.description = response.data.data.description;
        this.data.is_active = response.data.data.is_active;
        this.item = response.data.data.item;

        if (response.data.data.sub_items && response.data.data.sub_items.length > 0) {
          this.sub_items = response.data.data.sub_items;
        } else {
          this.addRepeater();
        }


      });
    },
    getItemsManufacture(f_by = null, filter = null) {
      if (filter && filter.length >= 3 && f_by)
        ApiService.get(this.mainRouteDependency + "/items_manufacture", {params: {[f_by]: filter}}).then((response) => {
          this.items_manufacture = response.data.data;
        });
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },
    async getUnits() {
      await ApiService.get(this.mainRouteDependency + "/units").then((response) => {
        this.units = response.data.data;

      });
    },



    addRepeater() {
      this.sub_item_repeater.unit = this.unit_default;
      this.sub_item_repeater.alternatives.push(this.sub_item_alternatives_repeater);
      this.sub_item_alternatives_repeater = {
        id: null,
        item_id: null,
        item: null,
        unit: null,
        unit_id: null,
        quantity: null,
        is_active: true
      };
      this.sub_items.push(this.sub_item_repeater);
      this.sub_item_repeater = {
        id: null,
        item_id: null,
        item: null,
        unit: null,
        unit_id: null,
        quantity: null,
        is_active: true,
        alternatives: []
      };
    },
    removeRepeater(index = 0) {
      if (this.sub_items.length > 1)
        this.sub_items.splice(index, 1);
    },
    addRepeaterAlternative() {

      this.sub_items[this.index_sub_item_row].alternatives.push(this.sub_item_alternatives_repeater);
      this.sub_item_alternatives_repeater = {
        id: null,
        item_id: null,
        item: null,
        unit: null,
        unit_id: null,
        quantity: null,
        is_active: true
      };
      this.sub_item_alternatives = this.sub_items[this.index_sub_item_row].alternatives;
    },
    removeRepeaterAlternative(index = 0) {
      if (this.sub_items[this.index_sub_item_row].alternatives.length > 1)
        this.sub_items[this.index_sub_item_row].alternatives.splice(index, 1);

      this.sub_item_alternatives = this.sub_items[this.index_sub_item_row].alternatives;
    },

    showModal(index) {
      this.index_sub_item_row = index;
      this.sub_item_alternatives = this.sub_items[index].alternatives;
      this.$refs['modal'].show()
    },
    hideModal() {
      this.index_sub_item_row = null;
      this.$refs['modal'].hide();
    },
    checkItemNotMain(index, event) {
      if (this.sub_items[this.index_sub_item_row].item.id == event.id) {
        this.sub_items[this.index_sub_item_row].alternatives[index].item = null;
      }
      this.sub_item_alternatives = this.sub_items[index].alternatives;
    },

    async defaultDataForUser(){
      await ApiService.get(this.mainRouteDependency +"/default_data_for_user").then((response) => {
        this.unit_default = response.data.data.unit;
        this.sub_item_repeater.unit = response.data.data.unit;
        if (this.sub_items[0]) {
          this.sub_items[0].unit = response.data.data.unit;
        }
      });
    },
    showItemModal(is_main, is_alternatives = false) {
      this.is_main = is_main;
      this.is_alternatives = is_alternatives;
      this.$refs['modalItem'].show();
    },
    hideItemModal() {
      this.$refs['modalItem'].hide();
    },
    getDataAfterCreateNewItem(object) {
      if (object && !this.is_main) {
        if (this.is_alternatives){
          this.sub_items[this.index_sub_item_row].alternatives[0].item = object;
          this.sub_items[this.index_sub_item_row].alternatives[0].item_id = object.id;
        }else {
          this.sub_items[0].item = object;
          this.sub_items[0].item_id = object.id;
        }
      }else{
        this.item = object;
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.material_list"),
      route: '/manufactories/material-list'
    }, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    this.getUnits();

    if (this.idEditing) {
      this.getData();
    } else {
      let promise = this.defaultDataForUser()
      Promise.all([promise]).then(() => {
        this.addRepeater();
      });
    }

  },
};
</script>